@import 'portal-carousel';
@import 'portal-home__artists';
@import 'portal-artist-area';
@import 'portal-ec-mall';
@import 'portal-category-area';
@import 'portal-live-ticket';
@import 'portal-artist-goods';

.portal-home {
    @include mobile-xxl {
        padding-top: 65px;
    }
    @include tablet {
        // padding-top: 115px;
        padding-top: 66px;
        width: 100%;
    }
    padding-bottom: 20px;
    background-color: $color-white;
    &__container {
        @include tablet {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &__content {
        @include tablet {
            max-width: 1200px;
            width: 100vw;
        }
    }
}
